import { Entity } from './'

export class Salon extends Entity {
  remoteGrouping = ''
  thumbnail = ''
  slug = ''
  address = ''

  get cardDescription () : string {
    return this.address
  }
}
