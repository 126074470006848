import { AxiosError } from 'axios'
import { Base, Region, Salon, Service, Professional, Slot } from './'

export enum ScheduleStatus {
  open = 'open',
  closed = 'closed',
  canceled = 'canceled'
}

export class ScheduleTotal extends Base {
  label: string
  value: string
  duration: string
  valueText: string
  durationText: string
  payableWithCredit: boolean
  note: string
}

export class Schedule extends Base {
  region: Region
  salon: Salon
  service: Service
  professional: Professional
  slot: Slot
  id: number
  status: ScheduleStatus
  date: string
  canCancel: boolean

  get canEdit () : boolean {
    return this.canCancel
  }

  public static fromJson (json: Record<string, any>) : any { // eslint-disable-line @typescript-eslint/no-explicit-any
    const s = super.fromJson(json) as Schedule
    s.salon = Salon.fromJson(json.salon)
    s.service = Service.fromJson(json.service)
    s.professional = Professional.fromJson(json.professional)
    s.slot = Slot.fromJson(json.slot)
    s.region = new Region(s.salon)
    return s as Schedule
  }
}

export function scheduleMessage (error: AxiosError) : string {
  let message = null
  try {
    if (error.response) {
      message = (error.response.status === 401) ? 'Seu cadastro está apresentando algum problema! Procure a unidade mais próxima.' : error.response.data.messages.join('\n\n')
    } else {
      message = error.message
    }
  } catch (e) {
    message = null
  }
  return message
}
